import { host} from "../../constants";
export default async function Signin({ email, password }) {
  try {
    const response = await fetch(`${host}/login`, {
      method: 'POST',
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    //  configHeader,
    });
    if (!response.ok) {
      throw new Error('Sign-in failed');
    }
    const data = await response.json();
     localStorage.setItem('token', data.access_token);
    return data;
  } catch (error) {
    console.log('error return');
    throw error;
  }
}
