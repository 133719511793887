export const host = "https://magnetic-daily-cougar.ngrok-free.app";
// export const host = "http://localhost:9080";
export const redirectUri = "chatoromia.org/oauth2/redirect";
export const GOOGLE_AUTH_URL =
  host + "/oauth2/authorize/google?redirect_uri=" + redirectUri;

export const configHeader = {
  headers: {
    // "ngrok-skip-browser-warning": "true",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-type": "application/json; charset=UTF-8",
  },
};

window.addEventListener('beforeunload', () => {
  localStorage.removeItem('token');
});
