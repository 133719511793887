import React, { useState, useEffect } from "react";
import { host } from "../../constants";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EmailIcon from '@mui/icons-material/AttachEmail';

import TimerIcon from '@mui/icons-material/Timer';
import { Link } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    backgroundColor: "whiteSmoke",
    borderRadius: "15px",
    width: "100%",
    height: "510px",

    maxWidth: "400px",
  },
  submit: {
    margin: theme.spacing(2, 0),
  },
  textField: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },
  successMessage: {
    backgroundColor: '#f0f9f4',
    border: '1px solid #4caf50',
    borderRadius: '8px',
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    width: '100%',
  },
  icon: {
    color: '#4caf50',
    marginRight: theme.spacing(1),
    fontSize: '1.2rem',
  },
  messageItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
    color: '#2e7d32',
    fontSize: '0.9rem',
  },
  timer: {
    color: '#ff9800',
    fontWeight: 'bold',
  }
}));

const validationSchema = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email address"
    ),
});

function ForgotPassword() {
  const classes = useStyles();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [timer, setTimer] = useState(30);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Timer countdown effect
  useEffect(() => {
    let interval;
    if (isEmailSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 60000); // Update every minute
    }
    return () => clearInterval(interval);
  }, [isEmailSent, timer]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        const response = await axios.post(
          `${host}/api/v1/user/forgotPassword/${values.email}`
        );
        setIsEmailSent(true);
        toast.success("Password reset email sent successfully!");
      } catch (error) {
        toast.error(error.response?.data?.message || "An error occurred. Please try again later.");
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <Typography
          align="center"
          component="h1"
          variant="h5"
          style={{ padding: 20 }}
          className={classes.texts}
        >
          <h3>
            <span style={{ color: "#C81810" }}>Visit-</span>Oromia
          </h3>

          {/* <h3 style={{ color: '#804000', textAlign: 'center', marginTop: '-50px' }}>
            <span style={{ color: '#00897B' }}>Visit-</span>Ethiopia
          </h3> */}

        </Typography>

        {!isEmailSent ? (
          <>
            <Typography variant="h6" style={{ marginBottom: '20px' }}>
              Forgot Your Password?
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ marginBottom: '20px', textAlign: 'center' }}>
              Enter your email address and we'll send you instructions to reset your password.
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                className={classes.textField}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                style={{ backgroundColor: isSubmitting ? "rgba(238, 102, 45, 0.7)" : "rgb(238 102 45)" }}
                className={classes.submit}
              >
                {isSubmitting ? "Sending..." : "Submit Email"}
              </Button>
            </form>
          </>
        ) : (
          <div className={classes.successMessage}>
            <div className={classes.messageItem}>
              <CheckCircleOutlineIcon className={classes.icon} />
              <Typography variant="body2">
                Password reset instructions sent!
              </Typography>
            </div>
            
            <div className={classes.messageItem}>
              <EmailIcon className={classes.icon} />
              <Typography variant="body2">
                Please check your email inbox at{' '}
                <strong>{formik.values.email}</strong>
              </Typography>
            </div>

            <div className={classes.messageItem}>
              <TimerIcon className={classes.icon} />
              <Typography variant="body2">
                The reset link will expire in{' '}
                <span className={classes.timer}>{timer} minutes</span>
              </Typography>
            </div>

            <LinearProgress 
              variant="determinate" 
              value={(timer / 30) * 100} 
              style={{ marginTop: '10px' }}
            />

            <Typography variant="body2" style={{ marginTop: '20px', color: '#666' }}>
              Didn't receive the email?{' '}
              <Button 
                color="primary"
                onClick={() => {
                  setIsEmailSent(false);
                  setTimer(30);
                }}
                style={{ textTransform: 'none' }}
              >
                Try again
              </Button>
            </Typography>

            <Typography variant="body2" style={{ marginTop: '10px', color: '#666', fontSize: '0.8rem' }}>
              Note: If you don't see the email in your inbox, please check your spam folder.
            </Typography>
          </div>
        )}

        {!isEmailSent && (
          <Typography variant="body2" style={{ marginTop: '20px', color: '#666' }}>
            Remember your password?{' '}
            <Link to="/login" style={{ color: 'rgb(238 102 45)', textDecoration: 'none' }}>
              Back to login
            </Link>
          </Typography>
        )}
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
}

export default ForgotPassword;
