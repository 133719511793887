import React, { useState } from "react";
import GoogleLoginButton from "./GoogleloginButton";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import gmailIcon from "./../../assets/images/Gmail_Logo_512px.png";
import Typography from "@material-ui/core/Typography";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import Signin from "../../services/api/Signin";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object({
  email: Yup.string()
    .required("Email is required")  // Check that email is provided
    .email("Invalid email address") // Validate email format
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address format"), // Custom regex for additional format checks
  password: Yup.string()
    .required("Password is required"),
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: "6px",
    paddingRight: "6px",
    paddingTop: "20px", // Added padding top

    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  root: {
    display: "flex",
    backgroundColor: "whiteSmoke",
    padding: "50px",
    paddingTop: "20px", // Added padding top

    justifyContent: "center",
    flexWrap: "nowrap",
    borderRadius: "15px",
    height: "650px",
    "& a": {
      color: "#3A6351",
    },
    [theme.breakpoints.down("md")]: {
      "& form": {
        padding: "0px",
      },
      padding: "0px",
      width: "100%",
      margin: "0px",
    },
  },

  form: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "20vw",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
    "@media (max-width:960px)": {},
  },
  submit: {
    background: "#3293A8",
    borderRadius: "5px",
    width: "100%",
    height: "50px",
    margin: theme.spacing(1, 0, 2),
    "&:hover": {
      background: "rgba(50, 147, 168,0.79)",
    },
    "@media (max-width:760px)": {
      width: "80%",
    },
  },

  login: {
    fontWeight: "800",
    marginLeft: "65px",
    "@media (max-width:760px)": {
      marginLeft: "15px",
    },
  },
  textField: {
    margin: "10px 0",
    borderRadius: "10px",
    width: "100%",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    border: "0px solid #eee",
    borderLeftWidth: "7px",
    borderLeftColor: "rgba(215,215,215,0.47)",
    "& input": {
      color: "rgba(57,50,50,0.25)",
      border: "0px solid #eee",
      height: "25px",
      borderRadius: "10px",
      width: "100%",
    },
    "@media (max-width:760px)": {
      width: "80%",
    },
  },
  texts: { marginLeft: "", "@media (max-width:760px)": {} },
  inputAdornment: {
    background: "rgba(215,215,215,0.87)",
    borderRadius: "7px 0px 0px 7px",
  },
}));

function Login() {
  const [error, setError] = useState("");
  const classes = useStyles();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema, // Apply the validation schema
    onSubmit: async (values) => {
      try {
        const data = await Signin({
          email: values.email,
          password: values.password,
        });
        if (data) {
          navigate("/dashboard/reports");
        }
      } catch (error) {
        setError("may you are on pending or other problem try later!");
      }
    },
  });
  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <form
          className={classes.form}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <Box style={{ height: "10px" }}>
            <h3 style={{ color: "red", padding: "0px", margin: "0px" }}>
              {error}
            </h3>
          </Box>

          <Typography
            align="center"
            component="h1"
            variant="h5"
            style={{ padding: 20 }}
            className={classes.texts}
          >
            <h3>
              <span style={{ color: "#C81810" }}>Visit-</span>Oromia{" "}
            </h3>

            {/* <h3 style={{ color: '#804000', textAlign: 'center', marginTop: '-50px' }}>
              <span style={{ color: '#00897B' }}>Visit-</span>Ethiopia
            </h3> */}

          </Typography>

          <TextField
            variant="outlined"
            margin="none"
            required
            fullWidth
            name="email"
            label="Email"
            type="text"
            id="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            autoComplete="current-password"
            className={classes.textField}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            variant="outlined"
            margin="none"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            autoComplete="current-password"
            className={classes.textField}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Typography
            align="center"
            variant="body2"
            style={{ padding: 10 }}
            className={classes.texts}
          >
            <Link href="/forgot-password" variant="body2">
              {"Forgot password"}
            </Link>
          </Typography>
          <Button
            id="login"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ backgroundColor: "rgb(238 102 45)" }}
            className={classes.submit}
          >
            Log in
          </Button>
          {/* <Typography
            align="center"
            variant="body2"
            style={{ padding: 10 }}
            className={classes.texts}
          >
           Or
          </Typography>
          <Button
                id="login"
               fullWidth
              variant="contained"
              color="primary"
                              

              className={classes.submit}
              style={{
                backgroundImage: `url(${gmailIcon})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                // border: 'none',
                // width: '70px', // Set the width and height to match the size of your icon
                // height: '48px',
                // padding: '0', // Remove padding to have the button fit the icon size
                cursor: 'pointer', // Change cursor to indicate it's clickable
              }}
            >
            Continue  with Gmail
          </Button> */}
          {/* <div>
				<h4><a href="/oauth2/authorization/google">Login with Google</a></h4>
			</div> */}

          <div container justify="center">
            <div>
              <Box
                style={{
                  fontWeight: "800",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box style={{ marginRight: "7px" }}>Don't have an account?</Box>
                <Link href="/signup" variant="body2" id="gotoSignup">
                  {"Sign Up"}
                </Link>
              </Box>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
